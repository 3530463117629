<template>
  <el-card class="cardCom">
    <div class="cardTitle x-f" v-if="!hideCard">
      <div class="goodsTitle" :class="{
          'nav-audit-icon': auditOrder,
          'nav-stop-icon': stopOrder,
          'nav-noAudit-icon': noAuditOrder,
          'nav-delete-icon': deleteOrder,
          'nav-disable-icon': disableOrder,
          'nav-cargo-icon': cargoOrder,
          'nav-noCargo-icon': noCargoOrder
        }">
        <div class="x-bc">
          <div class="x-f">
            <span class="title marR10" v-show="cardTitle">{{ cardTitle }}</span>
            <slot name="leftCardTitle"></slot>
            <el-tooltip effect="dark" v-if="tooltipText" :content="tooltipText" placement="top">
              <i class="el-icon-question" style="color: #a4a4a4"></i>
            </el-tooltip>
          </div>
          <div v-if="$slots.rightCardTitle">
            <slot name="rightCardTitle"></slot>
          </div>
        </div>
      </div>
    </div>
    <!-- 没使用插槽就不显示 -->
    <div v-if="$slots.cardContent" class="cardContent">
      <slot name="cardContent"></slot>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'cardTitleCom',
  data() {
    return {
      auditOrder: true, //审核图标
      stopOrder: true, //关闭图标
      noAuditOrder: true, //未审核图标
      deleteOrder: true, //删除图标
      disableOrder: true, //禁用图标
      cargoOrder: true, //已分货图标
      noCargoOrder: true //未分货图标
    }
  },
  props: {
    //问号提示内容
    tooltipText: {
      type: [String, Number],
      default: undefined
    },
    //标题
    cardTitle: {
      type: [String, Number],
      default: undefined
    },
    //状态
    billStatus: {
      type: [String, Number],
      default: ''
    },
    // 是否隐藏卡片
    hideCard: {
      type: Boolean,
      default: false
    },
    //是否是分货图标
    isCargoOrder: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    billStatus: {
      handler(newVal) {
        // console.log('billStatus', newVal)
        newVal = newVal + ''
        if (newVal == '' || !newVal) {
          // console.log('billStatus0', newVal)
          //审核图标
          this.auditOrder = false
          //关闭图标
          this.stopOrder = false
          //未审核图标
          this.noAuditOrder = false
          //删除图标
          this.deleteOrder = false
          //禁用图标
          this.disableOrder = false
          //已分货
          this.cargoOrder = false
          //未分货
          this.noCargoOrder = false
        } else if (newVal == '0') {
          console.log('billStatus2', newVal);
          //是否是分货
          if (this.isCargoOrder) {
            //未分货
            this.noCargoOrder = true
          } else {
            //未审核图标
            this.noAuditOrder = true
          }
          //审核图标
          this.auditOrder = false
          //关闭图标
          this.stopOrder = false
          //删除图标
          this.deleteOrder = false
          //禁用图标
          this.disableOrder = false
        } else if (newVal == '2') {
          //是否是分货
          if (this.isCargoOrder) {
            //已分货
            this.cargoOrder = true
          } else {
            //审核图标
            this.auditOrder = true
          }
          //未分货
          this.noCargoOrder = false
          //关闭图标
          this.stopOrder = false
          //未审核图标
          this.noAuditOrder = false
          //删除图标
          this.deleteOrder = false
          //禁用图标
          this.disableOrder = false
        } else if (newVal == '3') {
          //删除图标
          this.deleteOrder = true
          //审核图标
          this.auditOrder = false
          //关闭图标
          this.stopOrder = false
          //未审核图标
          this.noAuditOrder = false
          //禁用图标
          this.disableOrder = false
        } else if (newVal == '4') {
          //关闭图标
          this.stopOrder = true
          //审核图标
          this.auditOrder = false
          //未审核图标
          this.noAuditOrder = false
          //删除图标
          this.deleteOrder = false
          //禁用图标
          this.disableOrder = false
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.cardCom {
  margin-bottom: 6px;
  // 标题
  .cardTitle {
    padding-left: 10px;
    height: 40px;
    border-bottom: 1px solid #dedede;
    .goodsTitle {
      width: 100%;
    }
    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //已审核图标
    .nav-audit-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //已禁用图标
    .nav-disable-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/disable.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //已删除图标
    .nav-delete-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //已关闭图标
    .nav-stop-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //未分货图标
    .nav-cargo-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/cargo.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    //已分货图标
    .nav-noCargo-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/noCargo.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 20px);
        right: calc(6% - 70px);
      }
    }
    .title {
      color: #333333;
      font-weight: bold;
      font-size: 14px;
      padding-left: 6px;
      border-left: 3px solid #1596fd;
    }
  }
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
