// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/noAudit.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/audit.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/delete.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".com-card[data-v-2e964c4e] {\n  overflow: hidden;\n}\n.com-card .card[data-v-2e964c4e] {\n  margin: 5px;\n  margin-bottom: 0;\n  padding-bottom: 1px;\n  background-color: #fff;\n}\n.com-card .title[data-v-2e964c4e] {\n  border-bottom: 1px solid #e6e6e6;\n  margin-bottom: 20px;\n  font-weight: 700;\n  height: 30px;\n  line-height: 30px;\n  text-align: left;\n  font-size: 14px;\n}\n.com-card .title span[data-v-2e964c4e] {\n  border-left: 3px solid #289efd;\n  padding-left: 10px;\n  margin-left: 10px;\n}\n.com-card .nav-noAudit-icon[data-v-2e964c4e] {\n  position: relative;\n}\n.com-card .nav-noAudit-icon[data-v-2e964c4e]:after {\n  content: \"\";\n  position: absolute;\n  height: 64px;\n  width: 165px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100px 43px;\n  top: calc(28% - 32px);\n  right: calc(8% - 80px);\n}\n.com-card .nav-audit-icon[data-v-2e964c4e] {\n  position: relative;\n}\n.com-card .nav-audit-icon[data-v-2e964c4e]:after {\n  content: \"\";\n  position: absolute;\n  height: 64px;\n  width: 165px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: 100px 43px;\n  top: calc(28% - 32px);\n  right: calc(8% - 80px);\n}\n.com-card .nav-delete-icon[data-v-2e964c4e] {\n  position: relative;\n}\n.com-card .nav-delete-icon[data-v-2e964c4e]:after {\n  content: \"\";\n  position: absolute;\n  height: 64px;\n  width: 165px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  background-size: 100px 43px;\n  top: calc(28% - 32px);\n  right: calc(8% - 80px);\n}", ""]);
// Exports
module.exports = exports;
