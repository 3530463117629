<template>
  <el-card
    class="categoryTree"
    :style="{ height: `${boxHeight}px`, width: `${boxWidth}px` }"
  >
    <!-- 边框 -->
    <div class="treeBorder" :style="{ height: `${boxInternalHeight}px` }">
      <div class="treeTitle">
        <span class="leftTitle">{{ categoryTreeTitle }}</span>
        <div v-if="isEditBtn">
          <span class="handleEdit" v-show="isEdit" @click="clickEdit"
            >编辑</span
          >
          <span class="handleEdit" v-show="!isEdit" @click="clickEdit"
            >完成编辑</span
          >
        </div>
      </div>
      <div v-if="isMultipleEdits">
        <el-tabs v-model="activeName" type="card" @tab-click="tabPaneChange">
          <el-tab-pane label="区域" name="区域"></el-tab-pane>
          <el-tab-pane label="分组" name="分组"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="searchTreeBox">
        <el-input
          size="mini"
          class="searchTree"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          prefix-icon="el-icon-search"
        >
        </el-input>
      </div>
      <el-scrollbar :style="{ height: `${boxInternalHeight - 120}px` }">
        <keep-alive>
          <el-tree
            v-if="
              categoryTreeOptions.length > 0 || categoryTreeOptions2.length > 0
            "
            class="treeBody"
            highlight-current
            :data="
              activeName == '区域' ? categoryTreeOptions : categoryTreeOptions2
            "
            :node-key="defaultProps.id"
            :props="defaultProps"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            :default-expand-all="true"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-show="!isEdit">
                <el-button
                  v-show="
                    data.label != '未分类' &&
                    (data.id == 0 || data.id == -1 || !isOnlyOneLevel)
                  "
                  class="iconBtnTree"
                  icon="el-icon-circle-plus-outline"
                  type="text"
                  size="mini"
                  @click.native.stop="() => appendTree(data)"
                >
                </el-button>
                <el-button
                  v-show="
                    data.id != 0 && data.id != -1 && data.label != '未分类'
                  "
                  class="iconBtnTree"
                  icon="el-icon-edit"
                  type="text"
                  size="mini"
                  @click.native.stop="() => editTree(node, data)"
                >
                </el-button>
                <el-button
                  v-show="
                    data.id != 0 && data.id != -1 && data.label != '未分类'
                  "
                  class="iconBtnTree"
                  icon="el-icon-delete"
                  type="text"
                  size="mini"
                  @click.native.stop="() => removeTree(node, data)"
                >
                </el-button>
              </span>
            </span>
          </el-tree>
        </keep-alive>
      </el-scrollbar>
    </div>
    <!-- 是否包含半成品 -->
    <el-checkbox class="halfGoodsCheck" v-model="halfGoods" v-if="isNoProduct"
      >包含半成品
    </el-checkbox>
  </el-card>
</template>

<script>
export default {
  name: "categoryTreeCard",
  data() {
    return {
      defaultId: undefined, //选中
      activeName: "区域",
      isEdit: true, //编辑
      halfGoods: false, //半成品
      filterText: "", //节点过滤
    };
  },
  props: {
    //删除id
    removeTreeIdArr: {
      type: Array,
      default: () => [],
    },
    //多个编辑
    isMultipleEdits: {
      type: Boolean,
      default: false,
    },
    //卡片标题
    categoryTreeTitle: {
      type: String,
      default: "BOM分类",
    },
    boxHeight: {
      default: 936,
    },
    boxInternalHeight: {
      default: 736,
    },

    boxWidth: {
      type: Number,
      default: 354,
    },
    //树内容
    categoryTreeOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //树2内容
    categoryTreeOptions2: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //树节点修改
    isEditBtn: {
      type: Boolean,
      default: false,
    },
    //是否包含半成品
    isNoProduct: {
      type: Boolean,
      default: false,
    },
    //是否是商品所用
    isGoods: {
      type: Boolean,
      default: false,
    },
    // 是否改变数据时自动发送请求
    isVoluntarily: {
      type: Boolean,
      default: true,
    },
    //字段 (显示的字段/子级的字段)
    defaultProps: {
      type: Object,
      default: () => {
        return {
          id: "id",
          children: "children",
          label: "label",
        };
      },
    },
    // 分类下面的子级数组id，包括本身
    isChildLevelIds: {
      type: Boolean,
      default: false,
    },
    // 只能加一级
    isOnlyOneLevel: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    removeTreeIdArr: {
      handler(newVal) {
        if (newVal.length > 0 && newVal.some((v) => v == this.defaultId)) {
          this.defaultId = undefined;
        }
      },
    },
    categoryTreeOptions: {
      handler(newVal, oldVal) {
        if (newVal && this.isVoluntarily) {
          this.$nextTick(() => {
            if (this.defaultId) {
              // this.defaultshowKeys = [this.defaultId]
              this.$nextTick(() => {
                this.$refs.tree?.setCurrentKey(this.defaultId); //通过设置的ref设置setCurrentKey去渲染tree的默认选中
              });
              // !this.isGoods
            } else if (true) {
              document.querySelector(".el-tree-node__content")?.click();
            } else {
              this.$nextTick(() => {
                this.$emit("treeNodeId", {
                  categoryId:
                    this.categoryTreeOptions[0]?.children[0][
                      this.defaultProps.id
                    ],
                  categoryNo: this.categoryTreeOptions[0]?.children[0].number,
                });
                this.$refs.tree?.setCurrentKey(
                  this.categoryTreeOptions[0]?.children[0][this.defaultProps.id]
                ); //通过设置的ref设置setCurrentKey去渲染tree的默认选中
              });
            }
          });
        }
      },
      immediate: true,
    },
    // 根据名称筛选部门树
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    //半成品
    halfGoods() {
      this.$emit("isHalfGoods", this.halfGoods);
    },
  },
  methods: {
    //切换标签
    tabPaneChange() {
      if (this.isMultipleEdits) {
        this.$nextTick(() => {
          this.$refs.tree?.setCurrentKey(0);
          document.querySelector(".el-tree-node__content")?.click();
        });
      }
    },
    //新增Tree用户组
    appendTree(data) {
      console.log("data", data);
      if (this.isMultipleEdits) {
        data.activeName = this.activeName;
      }
      this.$emit("appendTree", data);
    },
    //编辑Tree用户组
    editTree(node, data) {
      if (this.isMultipleEdits) {
        data.activeName = this.activeName;
      }
      this.$emit("editTree", data);
    },
    //删除Tree用户组
    removeTree(node, data) {
      if (this.isMultipleEdits) {
        data.activeName = this.activeName;
      }
      this.$emit("removeTree", { node, data });
    },
    //编辑树
    clickEdit() {
      this.isEdit = !this.isEdit;
    },
    // 节点单击事件
    handleNodeClick(data) {
      if (this.isMultipleEdits) {
        this.$emit("treeNodeActiveName", this.activeName);
      }
      if (this.isChildLevelIds) {
        //获取父级和子级的id
        const nodeIds = [data.id];
        const childNodeIds = this.getChildNodeIds(data);
        const allNodeIds = nodeIds.concat(childNodeIds);
        this.$emit("allNodeIds", allNodeIds);
      } else if (this.isGoods) {
        this.$emit("treeNodeId", {
          categoryId: data[this.defaultProps.id],
          categoryNo: data.number,
        });
        this.defaultId = data[this.defaultProps.id];
      } else {
        this.$emit("treeNodeId", data[this.defaultProps.id],data);
        this.defaultId = data[this.defaultProps.id];
      }
    },
    //获取父级和子级的id
    getChildNodeIds(node) {
      let ids = [];
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          ids.push(child.id);
          ids = ids.concat(this.getChildNodeIds(child));
        }
      }
      return ids;
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
//整体树
.categoryTree {
  //width: 354px;
  margin-right: 6px;
  // 边框
  .treeBorder {
    margin: 15px 10px 0 10px;
    border: 1px solid #dfe6ec;
    // 树标题
    .treeTitle {
      margin: 12px 10px 16px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //左边
      .leftTitle {
        font-weight: 700;
        font-size: 14px;
      }

      //编辑
      .handleEdit {
        font-size: 14px;
        color: #1890ff;
        cursor: pointer;
      }
    }

    // 搜索框盒子
    .searchTreeBox {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      justify-content: center;
      padding-bottom: 12px;
      border-bottom: 1px solid #dfe6ec;
      //搜索框
      .searchTree {
        width: 308px;
      }
    }

    // 树
    //tree字体颜色
    .treeBody {
      padding-top: 16px;
      font-size: 14px;
      color: #333333;
    }
  }

  //半成品
  .halfGoodsCheck {
    margin: 10px 0 0 10px;
  }
}

//收银员用户组tree操作按钮区域样式
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .iconBtnTree {
    font-size: 14px;
    color: #999;
  }
}
/* element滚动条组件 隐藏水平滚动条 */
::v-deep .sidebar-wrapper .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .is-horizontal {
  display: none;
}
::v-deep ::-webkit-scrollbar-track {
  background: transparent;
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
