<template>
  <!-- 按钮区域组件 -->
  <div class="btn x-bc">
    <div>
      <el-button
      icon="el-icon-plus"
      size="mini"
      class="blue-btn"
      @click="handleBtn('新增')"
      v-if="isAddBtn"
      >新增</el-button>
    <el-dropdown
      split-button
      size="mini"
      icon="el-icon-circle-check"
      class="audit"
      v-if="isAuditBtn"
      @click="handleBtn('审核')"
      @command="handleBtn('反审核')"
    >
      审核
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="反审核">反审核</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown
      split-button
      size="mini"
      icon="el-icon-circle-check"
      class="startUsing"
      v-if="isStartUsingBtn"
      @click="handleBtn('启用')"
      @command="handleBtn('禁用')"
    >
      启用
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="禁用">禁用</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-button
      class="acidBlue-btn"
      icon="el-icon-picture-outline"
      size="mini"
      v-if="isCopyBtn"
      @click="handleBtn('复制')"
      >复制</el-button
    >
     <!-- 特殊自定义按钮  -->
     <slot name="specialDiyBtn"></slot>
    <el-button
      type="danger"
      size="mini"
      icon="el-icon-delete"
      v-if="isDelBtn"
      @click="handleBtn('删除')"
      >删除</el-button
    >
        <el-button
            size="mini"
            class="blue-btn"
            @click="handleBtn('退款')"
            v-if="isRefundBtn"
        >申请退款</el-button>
    </div>

    <el-button
      class="white-btn"
      icon="el-icon-picture-outline"
      size="mini"
      v-if="isRefreshBtn"
      @click="handleBtn('刷新')"
      >刷新</el-button
    >


  </div>
</template>

<script>
export default {
  name: 'ComBtn',
  props: {
    //是否开启新增按钮
    isAddBtn: {
      type: Boolean,
      default: true
    },
    //是否开启审核按钮
    isAuditBtn: {
      type: Boolean,
      default: true
    },
    //是否开启启用按钮
    isStartUsingBtn: {
      type: Boolean,
      default: true
    },
    //是否开启复制按钮
    isCopyBtn: {
      type: Boolean,
      default: true
    },
    //是否开启删除按钮
    isDelBtn: {
      type: Boolean,
      default: true
    },
    //是否开启刷新按钮
    isRefreshBtn: {
      type: Boolean,
      default: true
    },
      //是否开启申请退款按钮
      isRefundBtn: {
          type: Boolean,
          default: false
      }
  },
  methods: {
    //统一按钮事件  type区分按钮类型
    handleBtn (type) {
      this.$emit('clickBtn', type)
    }
  }
}
</script>

<style lang="scss" scoped>
//按钮区域
.btn {
  height: 50px;
  line-height: 50px;
  background-color: #f8f8f9;
  margin-bottom: 5px;
  .el-dropdown {
    //审核按钮
    &.audit {
      margin-left: 10px;
      margin-right: 5px;
      ::v-deep.el-button-group > .el-button {
        background-color: #3cbf1d !important;
        color: #fff;
      }
      ::v-deep.el-button-group > .el-button:hover {
        background-color: #60c947 !important;
      }
    }
    //启用按钮
    &.startUsing {
      margin-left: 5px;
      margin-right: 10px;
      ::v-deep.el-button-group > .el-button {
        background-color: #ff8a00 !important;
        color: #fff;
      }
      ::v-deep.el-button-group > .el-button:hover {
        background-color: #f9a13a !important;
      }
    }
  }
}
</style>
