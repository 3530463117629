<template>
    <div class="x-c btnBox" @click="click"
         :style="{'background': bgColor,'color': color,
         'cursor': !disabled ? 'pointer' : 'no-drop',
          'border': border != '' ? '1px solid ' + border : 'none'
          }">
        <i class="icon" v-if="icon" :class="icon"></i>
       <p>{{ test }}</p>
    </div>
</template>

<script>

export default {
    name: 'index',
    props: {
        // 如果只有两个汉字，又想增加文字的间距，请在文字中间加空格，css样式会自动识别转换为间距
        test: {
            type: String,
            required: true
        },
        // 文本颜色，白色按钮建议传：#606266（仿elementUi)
        color: {
            type: String,
            default: '#ffffff'
        },
        bgColor: {
            type: String,
            default: '#ffffff'
        },
        icon: {
            type: String,
            default: ''
        },
        disabled:{
           type: Boolean,
           default: false
        },
        // 边框颜色，白色按钮建议传：#DCDFE6（仿elementUi)
        border:{
            type: String,
            default: ''
        }
    },
    computed:{

    },
    methods:{
        click(){
            if(!this.disabled){
                this.$emit('click')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btnBox {
    padding: 0 10px;
    height: 33px;
    margin-right: 10px;
    border-radius: 3px;
    user-select: none;
    font-size: 14px;
    min-width: 90px;

    /*border: 1px solid #ffba00;*/

    p{
        word-spacing: 5px
    }
    .icon{
        margin: 0 5px;
    }
}


.btnBox:hover {
    opacity: 0.8;
}


</style>
