var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "categoryTree",
      style: { height: _vm.boxHeight + "px", width: _vm.boxWidth + "px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "treeBorder",
          style: { height: _vm.boxInternalHeight + "px" },
        },
        [
          _c("div", { staticClass: "treeTitle" }, [
            _c("span", { staticClass: "leftTitle" }, [
              _vm._v(_vm._s(_vm.categoryTreeTitle)),
            ]),
            _vm.isEditBtn
              ? _c("div", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit",
                        },
                      ],
                      staticClass: "handleEdit",
                      on: { click: _vm.clickEdit },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEdit,
                          expression: "!isEdit",
                        },
                      ],
                      staticClass: "handleEdit",
                      on: { click: _vm.clickEdit },
                    },
                    [_vm._v("完成编辑")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.isMultipleEdits
            ? _c(
                "div",
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "card" },
                      on: { "tab-click": _vm.tabPaneChange },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "区域", name: "区域" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "分组", name: "分组" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "searchTreeBox" },
            [
              _c("el-input", {
                staticClass: "searchTree",
                attrs: {
                  size: "mini",
                  placeholder: "输入关键字进行过滤",
                  "prefix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-scrollbar",
            { style: { height: _vm.boxInternalHeight - 120 + "px" } },
            [
              _c(
                "keep-alive",
                [
                  _vm.categoryTreeOptions.length > 0 ||
                  _vm.categoryTreeOptions2.length > 0
                    ? _c("el-tree", {
                        ref: "tree",
                        staticClass: "treeBody",
                        attrs: {
                          "highlight-current": "",
                          data:
                            _vm.activeName == "区域"
                              ? _vm.categoryTreeOptions
                              : _vm.categoryTreeOptions2,
                          "node-key": _vm.defaultProps.id,
                          props: _vm.defaultProps,
                          "filter-node-method": _vm.filterNode,
                          "expand-on-click-node": false,
                          "default-expand-all": true,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "span",
                                  { staticClass: "custom-tree-node" },
                                  [
                                    _c("span", [_vm._v(_vm._s(node.label))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isEdit,
                                            expression: "!isEdit",
                                          },
                                        ],
                                      },
                                      [
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                data.label != "未分类" &&
                                                (data.id == 0 ||
                                                  data.id == -1 ||
                                                  !_vm.isOnlyOneLevel),
                                              expression:
                                                "\n                  data.label != '未分类' &&\n                  (data.id == 0 || data.id == -1 || !isOnlyOneLevel)\n                ",
                                            },
                                          ],
                                          staticClass: "iconBtnTree",
                                          attrs: {
                                            icon: "el-icon-circle-plus-outline",
                                            type: "text",
                                            size: "mini",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (function () {
                                                return _vm.appendTree(data)
                                              })($event)
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                data.id != 0 &&
                                                data.id != -1 &&
                                                data.label != "未分类",
                                              expression:
                                                "\n                  data.id != 0 && data.id != -1 && data.label != '未分类'\n                ",
                                            },
                                          ],
                                          staticClass: "iconBtnTree",
                                          attrs: {
                                            icon: "el-icon-edit",
                                            type: "text",
                                            size: "mini",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (function () {
                                                return _vm.editTree(node, data)
                                              })($event)
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                data.id != 0 &&
                                                data.id != -1 &&
                                                data.label != "未分类",
                                              expression:
                                                "\n                  data.id != 0 && data.id != -1 && data.label != '未分类'\n                ",
                                            },
                                          ],
                                          staticClass: "iconBtnTree",
                                          attrs: {
                                            icon: "el-icon-delete",
                                            type: "text",
                                            size: "mini",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return (function () {
                                                return _vm.removeTree(
                                                  node,
                                                  data
                                                )
                                              })($event)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          593667869
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isNoProduct
        ? _c(
            "el-checkbox",
            {
              staticClass: "halfGoodsCheck",
              model: {
                value: _vm.halfGoods,
                callback: function ($$v) {
                  _vm.halfGoods = $$v
                },
                expression: "halfGoods",
              },
            },
            [_vm._v("包含半成品 ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }