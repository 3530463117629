<template>
  <div>
    <div class="tableBox">
      <el-table
        :data="tabData"
        border
        height="500px"
        ref="BillTable"
        v-loading="loading"
        :key="isKey"
        row-key="billId"
        @selection-change="handleSelectionChange"
      >
        <slot name="tableItem"></slot>
      </el-table>
      <div class="pagination-footer x-bc">
        <div class="left x-f">
          <span class="left x-f"
            >已选<span class="num">{{ multipleSelection.length }}</span
            >条</span
          >
          <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
            >选择全部</span
          >
          <span class="checkAll" v-show="isCheckAll" @click="selectAll"
            >取消选择</span
          >
        </div>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    tabData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      total: 0,
      ids: [], // 选中数组的Id
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },

      //更新表格视图
      isKey: true,
      // //判断当前是否全选数据
      isCheckAll: false,
      multipleSelection: [], // table已选择的数据

      btnDisabled: {
        isEdit: true, // 禁用编辑按钮
        isBulkEditing: true, //禁止审核按钮
        isDelete: true // 禁止删除按钮
      }
    }
  },
  methods: {
    // 获取列表数据
    getList () {
      this.$emit('event', 'getList')
    },
    handleSelectionChange (val) {
      console.log('选择行', val)
      this.multipleSelection = val
      let valL = val.length

      if (valL == 1) {
        this.btnDisabled.isEdit = false
        this.btnDisabled.isDelete = false
        this.btnDisabled.isBulkEditing = false
      } else if (valL > 1) {
        this.btnDisabled.isDelete = false
        this.btnDisabled.isBulkEditing = false
      } else if (valL < 1) {
        this.btnDisabled.isEdit = true
        this.btnDisabled.isBulkEditing = true
        this.btnDisabled.isDelete = true
      }
    },
    selectAll () {},
    // 审核
    handleAudit () {}
  }
}
</script>

<style lang="scss" scoped>
.tableBox {
  width: 100%;
}
</style>
