var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "x-c btnBox",
      style: {
        background: _vm.bgColor,
        color: _vm.color,
        cursor: !_vm.disabled ? "pointer" : "no-drop",
        border: _vm.border != "" ? "1px solid " + _vm.border : "none",
      },
      on: { click: _vm.click },
    },
    [
      _vm.icon ? _c("i", { staticClass: "icon", class: _vm.icon }) : _vm._e(),
      _c("p", [_vm._v(_vm._s(_vm.test))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }