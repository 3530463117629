var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "com-card" }, [
    _c(
      "div",
      {
        staticClass: "card",
        class: {
          "nav-noAudit-icon": _vm.icon === "0",
          "nav-audit-icon": _vm.icon === "2",
          "nav-delete-icon": _vm.icon === "3",
        },
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._t("content"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }