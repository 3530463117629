<template>
  <!-- 卡片组件 -->
  <div class="com-card">
    <div
      class="card"
      :class="{
        'nav-noAudit-icon': icon === '0',
        'nav-audit-icon': icon === '2',
        'nav-delete-icon': icon === '3'
      }"
    >
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComCard',
  props: {
    //标题
    title: {
      type: String,
      default: '基本信息'
    },
    //图标(未审核图标传‘0’，已审核图标传‘2’，删除图标传‘3’)
    icon: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.com-card {
  overflow: hidden;
  .card {
    margin: 5px;
    margin-bottom: 0;
    padding-bottom: 1px;
    background-color: #fff;
  }
  //标题
  .title {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
    font-weight: 700;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 14px;
    span {
      border-left: 3px solid #289efd;
      padding-left: 10px;
      margin-left: 10px;
    }
  }
  //未审核图标
  .nav-noAudit-icon {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 64px;
      width: 165px;
      background: url(~@/assets/images/noAudit.png) no-repeat;
      background-size: 100px 43px;
      top: calc(28% - 32px);
      right: calc(8% - 80px);
    }
  }

  //已审核图标
  .nav-audit-icon {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 64px;
      width: 165px;
      background: url(~@/assets/images/audit.png) no-repeat;
      background-size: 100px 43px;
      top: calc(28% - 32px);
      right: calc(8% - 80px);
    }
  }

  //已删除图标
  .nav-delete-icon {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 64px;
      width: 165px;
      background: url(~@/assets/images/delete.png) no-repeat;
      background-size: 100px 43px;
      top: calc(28% - 32px);
      right: calc(8% - 80px);
    }
  }
}
</style>
