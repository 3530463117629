<template>
    <!-- 表格 -->
    <div class="all-table">
        <el-table
            class="table"
            ref="dragTable"
            :data="tableData"
            row-key="columnId"
            border
            max-height="300"
        >
            <el-table-column
                label="序号"
                type="index"
                width="80"
                class-name="allowDrag"
                align="center"
            />
            <el-table-column align="center" label="操作" width="120" v-if="!disabled">
                <template v-slot="scope">
                    <i
                        @click="tableDelRow(scope.row)"
                        class="el-icon-remove operateDel"
                    ></i>
                </template>
            </el-table-column>
            <slot name="tableMiddle"></slot>
            <el-table-column
                v-for="item in columnLabel"
                :key="item.prop"
                :label="item.label"
                :align="item.align || 'center'"
                :prop="item.prop"
                :width="item.width"
                :minWidth="item.minWidth"
                show-overflow-tooltip
            >
            <template slot-scope="scope">
                <el-input v-model="scope.row[`${item.prop}`]" v-if="item.type == 'input'"> </el-input>
                <span v-else>{{ scope.row[`${item.prop}`]}}</span>
          </template>
            </el-table-column>
            <el-table-column >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'ComTable',
    props: {
        tableData: {   //表格数据
            type: Array
        },
        columnLabel: {   //表格列字段
            type: Array
        },
        //操作按钮区域隐藏
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        //删除行事件
        tableDelRow(row) {
            this.$emit('delTableRow', row)
        }
    }
}
</script>

<style>

</style>
